import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import happy from "../../assets/images/happy.svg";
import drink from "../../assets/images/drinker.svg";

const features = [
  {
    id: 1,
    title: "A Community-Driven Meme Coin",
    description:
      "At its core, Spookipup is a meme coin that leverages the power of community and creativity. Whether you're drawn to the excitement of new projects or simply looking for a lighthearted addition to your portfolio, Spookipup promises to deliver a unique blend of entertainment and opportunity. Our mission is to create a fun and engaging experience for our users, while also providing them with exciting opportunities.",
  },
  {
    id: 2,
    title: "Why Choose Spookipup?",
    description:
      "In a crowded space where countless meme coins are built on recycled jokes and familiar faces, Spookipup stands out by daring to be different. While many projects recycle existing memes for quick gains, Spookipup breaks the mold with its fresh, original concept - a haunted dog that's both adorable and eerie. We're not just another meme coin; we're a brand-new meme that captures the playful yet spooky spirit of our time.",
  },
  {
    id: 3,
    title: "Join the Movement",
    description:
      "So why settle for recycled memes when you can be part of something truly original? Spookipup is here to set a new standard, offering both a novel concept and a chance to be part of a groundbreaking movement in the meme coin world. Join us in embracing the supernatural charm of our haunted pup and be part of a new wave of meme innovation!",
  },
];

const FloatingIslandIndicator = ({ currentFeature, totalFeatures }) => {
  const position = (currentFeature / (totalFeatures - 1)) * 100;

  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: "80%", sm: "70%", md: "60%", lg: "50%" },
        height: "10px",
        backgroundColor: "transparent",
        borderRadius: "15px",
        overflow: "visible",
        mb: { xs: 1, sm: 2 },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: `${position}%`,
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "70%", sm: "60%", md: "55%", lg: "50%" },
          height: "10px",
          backgroundColor: "#E777A9",
          borderRadius: "10px",
          transition: "left 0.3s ease-in-out",
        }}
      />
    </Box>
  );
};

const About = () => {
  const scrollRef = useRef(null);
  const [currentFeature, setCurrentFeature] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollToFeature = (index) => {
    const featureWidth = scrollRef.current.scrollWidth / features.length;
    scrollRef.current.scrollTo({
      left: index * featureWidth,
      behavior: "smooth",
    });
    setCurrentFeature(index);
  };

  const handlePrev = () => {
    const newIndex = Math.max(0, currentFeature - 1);
    scrollToFeature(newIndex);
  };

  const handleNext = () => {
    const newIndex = Math.min(features.length - 1, currentFeature + 1);
    scrollToFeature(newIndex);
  };

  return (
    <Box
      id="about"
      sx={{
        padding: { xs: ".5rem", sm: "1rem", md: "2rem", lg: "3rem" },
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: { xs: "auto", sm: "100vh" },
        width: "100%",
      }}
    >
      <Box
        align="center"
        sx={{
          position: "relative",
          backgroundColor: "#FCC7DF",
          borderRadius: {
            xs: "30px",
            sm: "40px",
            md: "60px",
            lg: "80px",
          },
          p: { xs: 1.5, sm: 2, md: 3, lg: 4 },
          width: "100%",
          maxWidth: { xs: "95%", sm: "90%", md: "95%", lg: "95%" },
          border: "4px solid #ffff",
          color: "white",
          overflow: "visible",
          zIndex: 2,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 2, sm: 4, md: 6, lg: 8 }}
          alignItems="center"
          justifyContent="center"
        >
          {/* About Section */}
          <Box
            sx={{
              padding: { xs: 1.5, sm: 2, md: 3, lg: 4 },
              position: "relative",
              backgroundColor: "#ffff",
              borderRadius: {
                xs: "25px",
                sm: "35px",
                md: "45px",
                lg: "60px",
              },
              width: "100%",
              marginTop: {
                xs: "0",
                sm: "-5%",
                md: "-10%",
                lg: "-15%",
              },
              border: "4px solid #E777A9",
              color: "black",
              overflow: "hidden",
              zIndex: 1,
            }}
          >
            <Typography
              variant="h4"
              fontFamily={"Rubik Wet Paint"}
              sx={{
                fontSize: {
                  xs: "1.25rem",
                  sm: "1.5rem",
                  md: "1.8rem",
                  lg: "2.125rem",
                },
                mb: { xs: 1, sm: 1.5, md: 2 },
              }}
            >
              About
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "0.95rem",
                  md: "1rem",
                  lg: "1.1rem",
                },
                lineHeight: {
                  xs: 1.4,
                  sm: 1.5,
                  md: 1.6,
                  lg: 1.8,
                },
                padding: { xs: 1, sm: 1.5, md: 2 },
              }}
            >
              In the ever-evolving world of meme coins, where creativity meets
              cryptocurrency, Spookipup emerges as the latest sensation, a meme
              coin that's as fun as it is spooky!
            </Typography>
          </Box>

          {/* Features Section */}
          <Box
            sx={{
              padding: { xs: 1.5, sm: 2, md: 3, lg: 4 },
              position: "relative",
              backgroundColor: "#ffff",
              borderRadius: {
                xs: "25px",
                sm: "35px",
                md: "45px",
                lg: "60px",
              },
              width: "100%",
              marginBottom: {
                xs: "0",
                sm: "5%",
                md: "10%",
                lg: "15%",
              },
              border: "4px solid #E777A9",
              color: "black",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 3,
            }}
          >
            <Typography
              variant="h4"
              fontFamily={"Rubik Wet Paint"}
              sx={{
                mb: { xs: 1, sm: 1.5, md: 2 },
                mt: { xs: 1, sm: 1.5, md: 2 },
                fontSize: {
                  xs: "1.25rem",
                  sm: "1.5rem",
                  md: "1.8rem",
                  lg: "2.125rem",
                },
              }}
            >
              Features & Innovation
            </Typography>

            <FloatingIslandIndicator
              currentFeature={currentFeature}
              totalFeatures={features.length}
            />

            <Box
              align="center"
              sx={{
                position: "relative",
                flex: 2,
                width: "100%",
              }}
            >
              <Box
                ref={scrollRef}
                sx={{
                  display: "flex",
                  overflowX: "auto",
                  scrollSnapType: "x mandatory",
                  "&::-webkit-scrollbar": { display: "none" },
                  scrollbarWidth: "none",
                  height: "100%",
                  width: "100%",
                }}
              >
                {features.map((feature) => (
                  <Box
                    key={feature.id}
                    sx={{
                      minWidth: "100%",
                      scrollSnapAlign: "start",
                      padding: {
                        xs: 1.5,
                        sm: 2,
                        md: 3,
                        lg: 4,
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: {
                          xs: "1.1rem",
                          sm: "1.25rem",
                          md: "1.4rem",
                          lg: "1.5rem",
                        },
                        mb: { xs: 1, sm: 1.5, md: 2 },
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.8rem",
                          sm: "0.875rem",
                          md: "0.95rem",
                          lg: "1rem",
                        },
                        lineHeight: {
                          xs: 1.4,
                          sm: 1.5,
                          md: 1.6,
                          lg: 1.8,
                        },
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {/* Navigation Arrows - Show on tablet and desktop */}
              {(isTablet || isDesktop) && (
                <>
                  <IconButton
                    onClick={handlePrev}
                    disabled={currentFeature === 0}
                    sx={{
                      position: "absolute",
                      left: { sm: "1rem", md: "1.5rem" },
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                      },
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <IconButton
                    onClick={handleNext}
                    disabled={currentFeature === features.length - 1}
                    sx={{
                      position: "absolute",
                      right: { sm: "1rem", md: "1.5rem" },
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </Stack>
        <Stack>
          {/* Decorative Images - Only show on larger screens */}
          {!isMobile && (
            <>
              <Box
                component="img"
                src={happy}
                alt="happy"
                sx={{
                  position: "absolute",
                  width: {
                    sm: "150px",
                    md: "200px",
                    lg: "250px",
                  },
                  left: { sm: "2%", md: "-2%", lg: "-5%" },
                  bottom: {
                    sm: "-30%",
                    md: "-20%",
                    lg: "-25%",
                  },
                  zIndex: 3,
                  display: { xs: "none", sm: "block" },
                }}
              />
              <Box
                component="img"
                src={drink}
                alt="drink"
                sx={{
                  position: "absolute",
                  width: {
                    sm: "150px",
                    md: "200px",
                    lg: "250px",
                  },
                  top: { sm: "-20%", md: "-17%", lg: "-35%" },
                  right: { sm: "-1%", md: "-2%", lg: "-3%" },
                  zIndex: 3,
                  animation: "float 4s ease-in-out infinite",
                  display: { xs: "none", sm: "block" },
                }}
              />
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default About;
