import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  LinearProgress,
  keyframes,
} from "@mui/material";
import { styled } from "@mui/system";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  useAccount,
  useReadContract,
  useReadContracts,
  useWriteContract,
  useBalance,
} from "wagmi";
import { formatUnits, parseEther } from "viem";

import StatsBox from "../common/home/StatsBox";
import PurchaseSection from "../common/home/PurchaseSection";
import SocialMediaLinks from "../common/home/SocialMediaLinks";
import DocumentLinks from "../common/home/DocumentLinks";
import MarqueeSection from "../common/home/MarqueeSection";

import saleABI from "../../abi/PrivatePresale.json";
import LeftImage from "../../assets/images/runner.svg";
import coin from "../../assets/images/coin.svg";
// import coin2 from "../../assets/images/coin2.svg";
import BSCLogo from "../../assets/logos/bns-logo.svg";
import RainbowKitLogo from "../../assets/logos/rainbow.svg";
import FTMMainnetLogo from "../../assets/logos/ftm-mainnet-logo.svg";
import CryptoRankLogo from "../../assets/logos/cryptorank-logo.svg";
import EthereumLogo from "../../assets/logos/ethereum.svg";
import ChainlinkLogo from "../../assets/logos/chainlink.svg";
import KrakenLogo from "../../assets/logos/kraken.svg";
import MetamaskLogo from "../../assets/logos/metamask.svg";
import CoinSniperLogo from "../../assets/logos/coinsniper.svg";

const SVGBackground = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "#B2FFF9",
});
const WaveBox = styled(Box)({
  position: "relative",
  width: "100%",
  height: "100%",
});

const saleContract = {
  abi: saleABI,
  address:
    process.env.REACT_APP_SALE_ADDRESS ||
    "0x58dEE874C011220a309E35c121D5bBfe47dDAa3A",
};

const SaleState = Object.freeze({
  0: "NotStarted",
  1: "Active",
  2: "Paused",
  3: "Ended",
  4: "ClaimEnabled",
});

const ContractLink = styled("a")(({ theme }) => ({
  color: "black",
  textDecoration: "none",
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(1),
  transition: "color 0.2s ease-in-out",
  "&:hover": {
    color: "#E777A9",
  },
}));

const Home = () => {
  const { isConnected } = useAccount();
  const listingPrice = 0.05;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobiletab = useMediaQuery(theme.breakpoints.down("420px"));
  const { address } = useAccount();
  const { data: bnbData, isSuccess: fetchBalanceSuccess } = useBalance({
    address,
    chainId: 97, // BNB Testnet
    // chainId: 56 // BNB Mainnet
  });

  const [bnbBalance, setBnbBalance] = useState("0");
  useEffect(() => {
    if (fetchBalanceSuccess) {
      setBnbBalance(formatUnits(bnbData.value, bnbData.decimals));
    }
  }, [fetchBalanceSuccess, bnbData]);

  const [spkpBalance, setSpkpBalance] = useState("0");
  const { data: contibutionData, isSuccess: fetchContributionSuccess } =
    useReadContract({
      ...saleContract,
      functionName: "getBuyerContribution",
      args: [address],
    });
  useEffect(() => {
    if (fetchContributionSuccess) {
      setSpkpBalance(formatUnits(contibutionData, 18));
    }
  }, [fetchContributionSuccess, contibutionData]);

  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const { data: whitelistStatus, isSuccess: fetchWhitelistStatus } =
    useReadContract({
      ...saleContract,
      functionName: "isWhitelisted",
      args: [address],
    });
  useEffect(() => {
    if (fetchWhitelistStatus) {
      setIsWhitelisted(whitelistStatus);
    }
  }, [whitelistStatus, fetchWhitelistStatus]);

  const [stats, setStats] = useState({
    presalePrice: "Loading...",
    bnbPrice: "Loading...",
    totalQuantity: "Loading...",
    remainingQuantity: "Loading...",
    soldQuantity: "Loading...",
    presaleState: SaleState[0],
  });

  const { data, isSuccess } = useReadContracts({
    contracts: [
      { ...saleContract, functionName: "getPresalePrice" },
      { ...saleContract, functionName: "getBnbPrice" },
      { ...saleContract, functionName: "getTotalQuantity" },
      { ...saleContract, functionName: "getRemainingQuantity" },
      { ...saleContract, functionName: "getSoldQuantity" },
      { ...saleContract, functionName: "getPresaleState" },
    ],
  });

  const refetchStats = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const { writeContractAsync } = useWriteContract();

  const handleBuy = async (quantity) => {
    const presalePrice = parseFloat(stats.presalePrice);
    const bnbPrice = parseFloat(stats.bnbPrice);
    if (isNaN(presalePrice) || isNaN(bnbPrice))
      throw new Error("Invalid price values");
    const costBnb = ((presalePrice * quantity) / bnbPrice).toFixed(8);

    try {
      const res = await writeContractAsync({
        ...saleContract,
        functionName: "buyTokens",
        args: [quantity],
        value: parseEther(costBnb),
      });
      return { isBuySuccess: true, data: res };
    } catch (err) {
      return { isBuySuccess: false, data: err };
    }
  };

  const handleWhitepaperDownload = async () => {
    try {
      const response = await fetch("whitepaper.pdf");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      // Open the PDF in a new tab
      window.open(url, "_blank");
      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.download = "Spookipup_Whitepaper.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading whitepaper:", error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setStats({
        presalePrice: formatUnits(data[0].result, 6),
        bnbPrice: formatUnits(data[1].result, 8),
        totalQuantity: formatUnits(data[2].result, 18),
        remainingQuantity: formatUnits(data[3].result, 18),
        soldQuantity: formatUnits(data[4].result, 18),
        presaleState: SaleState[data[5].result],
      });
    }
  }, [isSuccess, data]);

  const marqueeContent = [
    { text: "BSC", logo: BSCLogo },
    { text: "RainbowKit", logo: RainbowKitLogo },
    { text: "FTM Mainnet", logo: FTMMainnetLogo },
    { text: "CryptoRank", logo: CryptoRankLogo },
    { text: "CoinSniper", logo: CoinSniperLogo },
    { text: "Chainlink", logo: ChainlinkLogo },
    { text: "Ethereum", logo: EthereumLogo },
    { text: "Metamask", logo: MetamaskLogo },
    { text: "Kraken", logo: KrakenLogo },
  ];
  const formatAddress = (address) => {
    if (!address) return "";
    const start = address.slice(0, 6);
    const end = address.slice(-4);
    return `${start}...${end}`;
  };
  // Add function to get BSC Scan URL
  const getBscScanUrl = (address) => {
    return `https://testnet.bscscan.com/address/${address}`;
    // const networkPrefix =
    //   process.env.REACT_APP_NETWORK === "testnet" ? "testnet." : "";
    // return `https://${networkPrefix}bscscan.com/address/${address}`;
  };

  const formatLargeNumber = (num) => {
    const number = Number(num);
    if (number >= 1e9) {
      const value = number / 1e9;
      const decimalPart = value.toString().split(".")[1];
      const decimals = decimalPart ? decimalPart.slice(0, 3) : "0";
      return `${Math.floor(value)}.${decimals}B`;
    } else if (number >= 1e6) {
      const value = number / 1e6;
      const decimalPart = value.toString().split(".")[1];
      const decimals = decimalPart ? decimalPart.slice(0, 3) : "0";
      return `${Math.floor(value)}.${decimals}M`;
    } else if (number >= 1e3) {
      const value = number / 1e3;
      const decimalPart = value.toString().split(".")[1];
      const decimals = decimalPart ? decimalPart.slice(0, 3) : "0";
      return `${Math.floor(value)}.${decimals}K`;
    }
    return number.toString();
  };

  const waveAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-8px) translateX(4px);
  }
  75% {
    transform: translateY(8px) translateX(-4px);
  }
`;

  // Create a styled span for animated letters
  const AnimatedLetter = styled("span")(({ delay }) => ({
    display: "inline-block",
    fontFamily: "Rubik Wet Paint",
    animation: `${waveAnimation} 2s ease-in-out infinite`,
    animationDelay: `${delay}s`,
  }));

  const text = "BUY-SPOOKIPUP";
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    const letterArray = text.split("").map((char, index) => ({
      char,
      delay: index * 0.05,
    }));
    setLetters(letterArray);
  }, []);

  return (
    <Box position="relative" overflow="hidden">
      <SVGBackground>
        <WaveBox>
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 1920 1048"
            preserveAspectRatio="none"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H1920V982C1920 982 1309.5 833.5 907.5 982C505.5 1130.5 0 982 0 982V0Z"
              fill="black"
            />
          </svg>
        </WaveBox>
      </SVGBackground>

      <Box id="home" sx={{ padding: { xs: 3, md: 10 } }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 4, md: 10, lg: 20 }}
          mt={{ xs: 5, md: 0 }}
          alignItems="center"
          justifyContent="center"
        >
          {!isMobile && !isMobiletab && (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                component="img"
                src={coin}
                alt="coin1"
                sx={{
                  position: "absolute",
                  zIndex: 0,
                  width: { xs: "60px", md: "100px" },
                }}
              />
              <Box
                component="img"
                src={LeftImage}
                alt="money"
                sx={{
                  top: { xs: "5%", md: "10%" },
                  width: {
                    xs: "80px",
                    sm: "150px",
                    md: "250px",
                    lg: "400px",
                  },
                  position: "relative",
                  left: { xs: "25%", md: "15%", lg: "5%" },
                  zIndex: 2,
                }}
              />
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                color="white"
                fontFamily={"Rubik Wet Paint"}
                sx={{
                  position: "relative",
                  zIndex: 2,
                  fontSize: { xs: "2.5rem", md: "3.5rem", lg: "4.5rem" },
                }}
              >
                Spookipup!
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              backgroundColor: "#ffff",
              borderRadius: "80px",
              border: "4px solid #EEADCA",
              position: "relative",
              p: { xs: 1, md: 2 },
              maxWidth: { xs: "100%", md: "50%", lg: "50%" },
              color: "black",
              zIndex: 3,
            }}
          >
            <Stack spacing={1} alignItems="center" mb={3}>
              <Typography
                variant="h4"
                align="center"
                sx={{
                  fontFamily: "Rubik Wet Paint",
                  fontWeight: 600,
                  fontSize: {
                    xs: "1.2rem",
                    md: "2rem",
                  },
                }}
              >
                {letters.map((letter, index) => (
                  <AnimatedLetter key={index} delay={letter.delay}>
                    {letter.char}
                  </AnimatedLetter>
                ))}
              </Typography>
              <ContractLink
                href={getBscScanUrl(
                  process.env.REACT_APP_TOKEN_ADDRESS ||
                    "0x050E3b1d6697844c27fdAF3D4E9F0DaE1C25879c"
                )}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  padding: "8px 16px",
                  color: "black",
                  borderRadius: "8px",
                  backgroundColor: "rgba(25, 255, 255, 0.3)",
                  fontSize: {
                    xs: "0.8rem",
                    sm: "0.9rem",
                    md: "1rem",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(25, 255, 255,  0.5)",
                    color: "black",
                  },
                }}
              >
                <span>
                  {formatAddress(
                    process.env.REACT_APP_TOKEN_ADDRESS ||
                      "0x050E3b1d6697844c27fdAF3D4E9F0DaE1C25879c"
                  )}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                  <polyline points="15 3 21 3 21 9" />
                  <line x1="10" y1="14" x2="21" y2="3" />
                </svg>
              </ContractLink>
            </Stack>

            <StatsBox
              stats={stats}
              listingPrice={listingPrice}
              SaleState={SaleState}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <ConnectButton showBalance={false} chainStatus={false} />
            </Box>
            <Box
              sx={{
                position: "relative",
                mt: 2,
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={
                  (Number(stats.soldQuantity) / Number(stats.totalQuantity)) *
                  100
                }
                sx={{
                  height: 40,
                  border: "3px solid #FFCCD5",
                  borderRadius: 5,
                  "& .MuiLinearProgress-bar": {
                    background:
                      "linear-gradient(90deg,#FFCCD5, #A0E7E5, #B4F8C8, #DBF6F9, #FBE4EF)",
                    backgroundSize: "400% 400%",
                    animation: "progressBarGradient 3s ease infinite",
                  },
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "80%",
                  width: "100%",
                  transform: "translate(-50%, -50%)",
                  color: "black",
                  fontWeight: "bold",
                  textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                  zIndex: 1,
                }}
              >
                {`REMAINING ${formatLargeNumber(stats.remainingQuantity)} `}
              </Typography>
            </Box>
            {isConnected ? null : <PurchaseSection stats={stats} />}
            {isConnected && (
              <PurchaseSection
                stats={stats}
                isConnected={isConnected}
                SaleState={SaleState}
                handleBuy={handleBuy}
                isMobile={isMobile}
                bnbBalance={bnbBalance}
                spkpBalance={spkpBalance}
                isWhitelisted={isWhitelisted}
                refetchStats={refetchStats}
              />
            )}

            <DocumentLinks
              handleWhitepaperDownload={handleWhitepaperDownload}
            />
            <SocialMediaLinks />
          </Box>
        </Stack>

        <MarqueeSection marqueeContent={marqueeContent} />
      </Box>
    </Box>
  );
};

export default Home;
