import React from "react";
import { Box, Typography, Stack } from "@mui/material";

const StatsBox = ({ stats, listingPrice, SaleState }) => {
  const loadStats = () => {
    switch (stats.presaleState) {
      case SaleState[0]: // Not Started
        return (
          <Box>
            <Typography variant="h6" align="center">
              Sale is yet to start!
            </Typography>
          </Box>
        );
      case SaleState[1]: // Active
      case SaleState[2]: // Paused
        return (
          <Box align="center">
            <Stack
              sx={{
                ml: { xs: "0%", sm: "0%", md: "5%" },
                mr: { xs: "0%", sm: "0%", md: "5%" },
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="body1" fontWeight="400">
                  1 SPKP = ${stats.presalePrice}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" fontWeight="400">
                  Listing Price = ${listingPrice}
                </Typography>
              </Box>
            </Stack>
          </Box>
        );
      case SaleState[3]: // Ended
        return (
          <Box alignItems="center">
            <Typography variant="h6" align="center">
              Sale has ended, stay tuned for further updates.
            </Typography>
          </Box>
        );
      default:
        return (
          <Box>
            <Typography>Loading...</Typography>
          </Box>
        );
    }
  };

  return <Box align="center">{loadStats()}</Box>;
};

export default StatsBox;
