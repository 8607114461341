import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Stack,
  InputAdornment,
  Typography,
  Link,
  Tooltip,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import SpookipupLogo from "../../../assets/logos/logo-black.svg";
import TransactionModal, { ModalStatus } from "../modal/TransactionModal";
import { estimateGas, estimateFeesPerGas } from "@wagmi/core";
import { encodeFunctionData, parseEther, formatUnits } from "viem";
import { config } from "../../../wagmi";

const formatNumber = (num) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};

// Network name mapping for different chain IDs
const NETWORK_NAMES = {
  56: "Binance Smart Chain",
  97: "BSC Testnet",
};

const PurchaseSection = ({
  stats,
  isConnected,
  SaleState,
  handleBuy,
  isMobile,
  bnbBalance,
  spkpBalance,
  isWhitelisted,
  refetchStats,
}) => {
  const [networkInfo, setNetworkInfo] = useState({ name: "", chainId: null });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [errorDetails, setErrorDetails] = useState("");

  /**
   * Effect hook to detect and monitor network changes
   * Sets up listeners for network changes when wallet is connected
   */
  useEffect(() => {
    const detectNetwork = async () => {
      if (window.ethereum && isConnected) {
        try {
          const chainId = await window.ethereum.request({
            method: "eth_chainId",
          });
          const decimalChainId = parseInt(chainId, 16);
          setNetworkInfo({
            name: NETWORK_NAMES[decimalChainId] || "Unknown Network",
            chainId: decimalChainId,
          });

          window.ethereum.on("chainChanged", (newChainId) => {
            const newDecimalChainId = parseInt(newChainId, 16);
            setNetworkInfo({
              name: NETWORK_NAMES[newDecimalChainId] || "Unknown Network",
              chainId: newDecimalChainId,
            });
          });
        } catch (error) {
          console.error("Error detecting network:", error);
          setNetworkInfo({
            name: "Network Detection Failed",
            chainId: null,
          });
        }
      }
    };

    detectNetwork();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("chainChanged", () => {});
      }
    };
  }, [isConnected]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setErrorDetails("");
    setModalMessage("");
    refetchStats();
  };

  const getEstimateMaxGasFees = async (amount, qty) => {
    try {
      const address = "0x58dEE874C011220a309E35c121D5bBfe47dDAa3A";
      const abi = [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenQty",
              type: "uint256",
            },
          ],
          name: "buyTokens",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ];

      const gasPrices = await estimateFeesPerGas(config);
      const baseFee = gasPrices.gasPrice || 0n;
      const maxPriorityFeePerGas = gasPrices.maxPriorityFeePerGas || 0n;

      const estimatedGasUnits = await estimateGas(config, {
        to: address,
        data: encodeFunctionData({
          abi: abi,
          args: [qty],
        }),
        value: parseEther(amount.toString()),
      });

      const totalGasInWei =
        (baseFee + maxPriorityFeePerGas) * estimatedGasUnits;
      const totalGasFees = formatUnits(totalGasInWei, 18);

      return { isSuccess: true, res: totalGasFees };
    } catch (err) {
      return { isSuccess: false, res: err };
    }
  };

  const wrappedHandleBuy = async () => {
    /***************************
     * Clientside Handling
     ************************* */
    // Wallet connection
    if (!isConnected) {
      setModalStatus(ModalStatus.CONNECT_WALLET);
      setModalMessage(
        <Typography
          id="transaction-modal-description"
          textAlign="center"
          color="text.secondary"
        >
          Please connect your wallet to continue.
        </Typography>
      );
      setModalOpen(true);
      return;
    }

    if (!isWhitelisted) {
      setModalStatus(ModalStatus.REJECTED);
      setModalMessage(
        <>
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            Connected wallet is not whitelisted.
          </Typography>
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            To get whitelisted, please contact existing investors or send query
            to{" "}
            <Link href="mailto:info@spookipup.meme">info@spookipup.meme</Link>.
          </Typography>
        </>
      );
      setModalOpen(true);
      return;
    }

    if (parseFloat(bnbQty) <= 0 || spkpQty <= 0) {
      return;
    }

    if (parseFloat(bnbQty) >= bnbBalance) {
      // Buying more than current wallet balance
      setModalStatus(ModalStatus.INSUFFICIENT_BALANCE);
      setModalMessage(
        <Typography
          id="transaction-modal-description"
          textAlign="center"
          color="text.secondary"
        >
          Insufficient BNB balance.
        </Typography>
      );
      setModalOpen(true);
      return;
    }

    // Buying more than remaining tokens
    if (spkpQty > stats.remainingQuantity) {
      // Buying more than current wallet balance
      setModalStatus(ModalStatus.REJECTED);
      setModalMessage(
        <Typography
          id="transaction-modal-description"
          textAlign="center"
          color="text.secondary"
        >
          Please enter a valid amount of SPKP.
        </Typography>
      );
      setModalOpen(true);
      return;
    }

    /***************************
     * Chain Handling
     ************************* */
    // Gas Fees Estimation
    const gasFees = await getEstimateMaxGasFees(bnbQty, spkpQty);

    // If balance is less than required balance (inclusive of gas)
    if (gasFees.isSuccess) {
      const requiredBnb = parseFloat(bnbQty) + parseFloat(gasFees.res);
      if (bnbBalance <= requiredBnb) {
        setModalStatus(ModalStatus.INSUFFICIENT_BALANCE);
        setModalMessage(
          <>
            <Typography
              id="transaction-modal-description"
              textAlign="center"
              color="text.secondary"
            >
              Please have sufficient BNB balance to cover payment and gas fees.
            </Typography>
            <Typography
              id="transaction-modal-description"
              textAlign="center"
              color="text.secondary"
            >
              <b>Current =</b> {parseFloat(bnbBalance).toFixed(8)} BNB<br></br>
              <b>Required =</b> {requiredBnb.toFixed(8)} BNB
            </Typography>
            <Typography
              id="transaction-modal-description"
              textAlign="center"
              color="text.secondary"
            >
              Sometimes wallets can provide a false positive. To resolve that,{" "}
              <Link
                href="https://docs.spookipup.meme/participation/presale-help-section#payment-issues"
                target="_blank"
              >
                see this
              </Link>
              .
            </Typography>
          </>
        );
        setModalOpen(true);
        return;
      }
    } else {
      if (gasFees.data.name === "EstimateGasExecutionError") {
        setModalStatus(ModalStatus.ERROR);
        setModalMessage(
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            Estimated gas is more than wallet balance!
          </Typography>
        );
        setModalOpen(true);
      } else {
        setModalStatus(ModalStatus.ERROR);
        setModalMessage(
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            There is some error estimating gas fees! Please try again later.
          </Typography>
        );
        setModalOpen(true);
      }
    }

    /***************************
     * Processing Buy
     ************************* */
    setModalStatus(ModalStatus.PROCESSING);
    setModalMessage(
      <>
        <Typography
          id="transaction-modal-description"
          textAlign="center"
          color="text.secondary"
        >
          Processing your transaction...
        </Typography>
        <Typography>
          Sometimes, wallets can provide inaccurate or high gas fees. To resolve
          that,{" "}
          <Link
            href="https://docs.spookipup.meme/participation/presale-help-section#payment-issues"
            target="_blank"
          >
            see this
          </Link>
        </Typography>
      </>
    );
    setModalOpen(true);

    const res = await handleBuy(spkpQty);
    if (res.isBuySuccess) {
      setModalStatus(ModalStatus.SUCCESS);
      const blockExplorerLink = `https://testnet.bscscan.com/tx/${res.data}`;
      setModalMessage(
        <>
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            {`Transaction for ${spkpQty.toString()} SPKP tokens approved!`}
          </Typography>
          <Link
            sx={{ display: "flex", justifyContent: "center" }}
            href={blockExplorerLink}
            target="_blank"
          >
            Verify Transaction
          </Link>
        </>
      );
    } else {
      if (res.data.name === "ContractFunctionExecutionError") {
        setModalStatus(ModalStatus.REJECTED);
        setModalMessage(
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            The transaction got rejected in your wallet!
          </Typography>
        );
        setModalOpen(true);
      } else {
        setModalStatus(ModalStatus.ERROR);
        setModalMessage(
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            There is some unexpected error! Please try again later.
          </Typography>
        );
        setModalOpen(true);
      }
    }
  };

  const costInBnb = parseFloat(
    (stats.presalePrice / stats.bnbPrice).toFixed(8)
  ); // Parse to float once for reuse
  const buttonValues = [160000, 320000, 1600000, 3200000];
  const [bnbQty, setBnbQty] = useState("0");
  const [spkpQty, setSpkpQty] = useState(0);
  const theme = useTheme();
  const isMobiletab = useMediaQuery(theme.breakpoints.down("lg"));
  const handleBnbQtyChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const input = event.target.value;
    if (/^\d*\.?\d*$/.test(input)) {
      const decimalPart = input.split(".")[1];
      if (decimalPart && decimalPart.length > 8) {
      }
      setBnbQty(input);
      if (input.endsWith(".")) {
        return;
      }
      const newBnbQty = parseFloat(input);
      if (!isNaN(newBnbQty) && newBnbQty >= 0) {
        const calculatedSpkpQty = Math.floor(newBnbQty / costInBnb);
        setSpkpQty(calculatedSpkpQty);
      } else {
        setSpkpQty(0);
      }
    }
  };

  const handleSpkpQtyChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const input = event.target.value;
    if (/^\d*$/.test(input)) {
      const newSpkpQty = parseInt(input, 10);
      if (isNaN(newSpkpQty) || newSpkpQty < 0) {
        setBnbQty("0");
        setSpkpQty(0);
      } else {
        const calculatedBnbQty = (newSpkpQty * costInBnb).toFixed(8);
        setSpkpQty(newSpkpQty);
        setBnbQty(calculatedBnbQty);
      }
    } else {
      setBnbQty("0");
      setSpkpQty(0);
    }
  };

  const handleMaxClick = async () => {
    if (!isWhitelisted) {
      setModalStatus(ModalStatus.REJECTED);
      setModalMessage(
        <>
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            Connected wallet is not whitelisted.
          </Typography>
          <Typography
            id="transaction-modal-description"
            textAlign="center"
            color="text.secondary"
          >
            To get whitelisted, please contact existing investors or send query
            to{" "}
            <Link href="mailto:info@spookipup.meme">info@spookipup.meme</Link>.
          </Typography>
        </>
      );
      setModalOpen(true);
      return;
    }

    const newBnbQty = parseFloat(bnbBalance);
    if (isNaN(newBnbQty) || newBnbQty < 0) {
      setBnbQty("0");
      setSpkpQty(0);
    } else {
      const calculatedSpkpQty = Math.ceil(newBnbQty / costInBnb);
      const estimatedGasFees = await getEstimateMaxGasFees(
        newBnbQty.toString(),
        calculatedSpkpQty
      );
      if (!estimatedGasFees.isSuccess) return;

      const netBnbQty = newBnbQty - parseFloat(estimatedGasFees.res);
      const finalSpkpQty = Math.floor(netBnbQty / costInBnb);

      setBnbQty(netBnbQty.toFixed(8));
      setSpkpQty(finalSpkpQty);
    }
  };

  const handleCommonQty = (qty) => {
    const newSpkpQty = parseInt(qty, 10);
    if (isNaN(newSpkpQty) || newSpkpQty < 0) {
      setSpkpQty(0);
      setBnbQty("0.00000000");
    } else {
      const calculatedBnbQty = (newSpkpQty * costInBnb).toFixed(8);
      setSpkpQty(newSpkpQty);
      setBnbQty(calculatedBnbQty);
    }
  };
  return (
    <Box maxWidth="sm" sx={{ mt: 2 }}>
      {isConnected && (
        <Box sx={{ mb: 2, textAlign: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Connected to: {networkInfo.name}
          </Typography>
        </Box>
      )}
      {isConnected && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ flexWrap: "wrap", justifyContent: "center", gap: 1 }}
        >
          {buttonValues.map((value) => (
            <Button
              key={value}
              variant="outlined"
              sx={{
                color: "black",
                border: "3px solid #B2FFF9",
                borderRadius: "80px",
              }}
              onClick={() => handleCommonQty(value)}
              disabled={value > parseFloat(stats.remainingQuantity)}
            >
              {formatNumber(value)}
            </Button>
          ))}
        </Stack>
      )}
      <Stack
        direction={isMobiletab ? "column" : "row"}
        spacing={2}
        sx={{ mt: 2, mb: 2 }}
      >
        <TextField
          fullWidth
          label="You pay BNB"
          value={bnbQty}
          onChange={handleBnbQtyChange}
          size="small"
          inputProps={{
            inputMode: "decimal",
            pattern: "[0-9]*[.]?[0-9]*",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isConnected && (
                  <Button
                    variant="text"
                    size="small"
                    onClick={handleMaxClick}
                    sx={{
                      minWidth: "auto",
                      px: 1,
                      color: "black",
                    }}
                  >
                    MAX
                  </Button>
                )}
              </InputAdornment>
            ),
          }}
          helperText={
            <Tooltip
              arrow
              enterTouchDelay={0}
              disableFocusListener
              title="Your balance of BNB in the wallet you connected."
            >
              <span
                style={{
                  display: "flex",
                  color: "black",
                  justifyContent: "center",
                  textDecoration: "underline",
                  userSelect: "none",
                }}
              >
                BNB Balance: {bnbBalance}{" "}
                {
                  <HelpIcon
                    fontSize="small"
                    sx={{
                      ml: 0.5,
                      color: "grey.600",
                      cursor: "pointer",
                      ":hover": {
                        cursor: "default",
                      },
                    }}
                  />
                }
              </span>
            </Tooltip>
          }
        />
        <TextField
          fullWidth
          label="You receive SPKP"
          value={spkpQty}
          onChange={handleSpkpQtyChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={SpookipupLogo}
                  alt="Spookipup Logo"
                  style={{ height: 24 }}
                />
              </InputAdornment>
            ),
          }}
          helperText={
            <Tooltip
              arrow
              enterTouchDelay={0}
              disableFocusListener
              title="Your total balance of Spookipup tokens bought in presale."
            >
              <span
                style={{
                  display: "flex",
                  color: "black",
                  justifyContent: "center",
                  textDecoration: "underline",
                  userSelect: "none",
                }}
              >
                SPKP Balance: {spkpBalance}{" "}
                {
                  <HelpIcon
                    fontSize="small"
                    sx={{
                      ml: 0.5,
                      color: "grey.600",
                      cursor: "pointer",
                      ":hover": {
                        cursor: "default",
                      },
                    }}
                  />
                }
              </span>
            </Tooltip>
          }
        />
      </Stack>
      {isConnected && (
        <Box sx={{ margin: "1rem 0" }}>
          <Alert variant="outlined" severity="info">
            <b>Note: </b>Ensure you maintain a sufficient reserve of tokens to
            cover gas fees, as insufficient funds may result in transaction
            errors.
          </Alert>
        </Box>
      )}
      {isConnected && (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={wrappedHandleBuy}
            disabled={parseFloat(bnbQty) <= 0 || spkpQty <= 0}
            sx={{
              borderRadius: "80px",
              border: "4px solid #FFAE5F",
              fontSize: "1.2rem",
              color: "black",
              width: "100%",
              fontFamily: "Rubik Wet Paint",
              textTransform: "none",
              backgroundColor: "#FFC389",
              "&:hover": {
                backgroundColor: "#FFC389",
              },
              "&:disabled": {
                backgroundColor: "rgba(255, 217, 180, 0.5)",
                color: "rgba(111,111, 111, 0.7)",
              },
            }}
          >
            {!isConnected
              ? "Connect Wallet"
              : stats.presaleState !== SaleState[1]
                ? "Sale Not Active"
                : "Buy Now"}
          </Button>
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          textAlign: "center",
          color: "text.secondary",
        }}
      >
        Current BNB Price: ${stats.bnbPrice}
        <br />1 SPKP = ${stats.presalePrice} | {costInBnb} BNB
      </Box>

      <TransactionModal
        open={modalOpen}
        onClose={handleCloseModal}
        status={modalStatus}
        message={modalMessage}
        errorDetails={errorDetails}
      />
    </Box>
  );
};

export default PurchaseSection;
