import React from "react";
import { Box, Typography } from "@mui/material";
import { Telegram } from "@mui/icons-material";

const SocialMediaLinks = () => (
  <Box
    align="center"
    mt={2}
    gap={4}
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="center"
  >
    <a
      href="https://t.me/spookipup_bot"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Telegram
          fontSize="large"
          label="Telegram"
          sx={{
            color: "#B2A8D1",
            "&:hover": { borderColor: "#E777A9", color: "#E777A9" },
          }}
        />
        <Typography variant="body2">Telegram</Typography>
      </Box>
    </a>
  </Box>
);

export default SocialMediaLinks;
