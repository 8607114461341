import React from "react";
import { Box, Button, Stack } from "@mui/material";
const styles = {
  "&.MuiButton-outlined": {
    color: "Black",
    border: "1px solid black",
    fontFamily: "Rubik Wet Paint",
    minWidth: { xs: "80px", sm: "100px" },
  },
};
const DocumentLinks = ({ handleWhitepaperDownload }) => (
  <Stack spacing={1} alignItems="center" mt={2}>
    <Box align="center">
      <Stack direction="row" spacing={1}>
        <Button
          sx={{
            ...styles,
            "&:hover": { borderColor: "#E777A9", color: "#E777A9" },
          }}
          variant="outlined"
          onClick={handleWhitepaperDownload}
        >
          Whitepaper
        </Button>
        <Button
          sx={{
            ...styles,
            "&:hover": { borderColor: "#E777A9", color: "#E777A9" },
          }}
          variant="outlined"
        >
          Audit
        </Button>
        <a
          href="https://docs.spookipup.meme/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            sx={{
              ...styles,
              "&:hover": {
                borderColor: "#E777A9",
                color: "#E777A9",
              },
            }}
            variant="outlined"
          >
            Docs
          </Button>
        </a>
      </Stack>
    </Box>
  </Stack>
);

export default DocumentLinks;
