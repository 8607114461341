// TransactionModal.js
import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
  Collapse,
  Paper,
} from "@mui/material";
import {
  CheckCircle,
  XCircle,
  AlertCircle,
  Wallet,
  Copy,
  ChevronDown,
  ChevronUp,
} from "lucide-react";

// Modal status types
export const ModalStatus = {
  CONNECT_WALLET: "connect_wallet",
  PROCESSING: "processing",
  SUCCESS: "success",
  INSUFFICIENT_BALANCE: "insufficient_balance",
  ERROR: "error",
  REJECTED: "rejected",
};

const TransactionModal = ({ open, onClose, status, message, errorDetails }) => {
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const handleCopyError = () => {
    navigator.clipboard.writeText(errorDetails);
  };

  const getStatusContent = () => {
    switch (status) {
      case ModalStatus.CONNECT_WALLET:
        return {
          icon: <Wallet size={64} color="#FFC389" />,
          title: "Connect Wallet",
          message: message,
          buttonText: "Close",
        };
      case ModalStatus.PROCESSING:
        return {
          icon: <CircularProgress size={64} sx={{ color: "#FFC389" }} />,
          title: "Processing Transaction",
          message: message,
          buttonText: null,
        };
      case ModalStatus.SUCCESS:
        return {
          icon: <CheckCircle size={64} color="#4CAF50" />,
          title: "Success!",
          message: message,
          buttonText: "Close",
        };
      case ModalStatus.INSUFFICIENT_BALANCE:
        return {
          icon: <AlertCircle size={64} color="#FFC107" />,
          title: "Insufficient Balance",
          message: message,
          buttonText: "Close",
        };
      case ModalStatus.REJECTED:
        return {
          icon: <XCircle size={64} color="#FF9800" />,
          title: "Transaction Rejected",
          message: message,
          buttonText: "Close",
        };
      case ModalStatus.ERROR:
        return {
          icon: <XCircle size={64} color="#F44336" />,
          title: "Transaction Failed",
          message: message,
          buttonText: "Close",
        };
      default:
        return {
          icon: <AlertCircle size={64} color="#FFC107" />,
          title: "Unknown Status",
          message: (
            <>
              <Typography
                id="transaction-modal-description"
                textAlign="center"
                color="text.secondary"
              >
                Something went wrong.
              </Typography>
            </>
          ),
          buttonText: "Close",
        };
    }
  };

  const content = getStatusContent();

  return (
    <Modal
      open={open}
      onClose={status !== ModalStatus.PROCESSING ? onClose : undefined}
      aria-labelledby="transaction-modal-title"
      aria-describedby="transaction-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 400 },
          maxHeight: "90vh",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "24px",
          border: "4px solid #FFAE5F",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Stack spacing={2} alignItems="center">
          {content.icon}

          <Typography
            id="transaction-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
            fontWeight="bold"
          >
            {content.title}
          </Typography>

          <Box>{message}</Box>

          {errorDetails && (
            <Box sx={{ width: "100%" }}>
              <Button
                onClick={() => setShowErrorDetails(!showErrorDetails)}
                endIcon={
                  showErrorDetails ? (
                    <ChevronUp size={20} />
                  ) : (
                    <ChevronDown size={20} />
                  )
                }
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  color: "error.main",
                  textTransform: "none",
                }}
              >
                Show Error Details
              </Button>

              <Collapse in={showErrorDetails}>
                <Paper
                  sx={{
                    mt: 1,
                    p: 2,
                    bgcolor: "grey.100",
                    borderRadius: 1,
                    position: "relative",
                    fontFamily: "monospace",
                    fontSize: "0.875rem",
                    maxHeight: "200px",
                    overflow: "auto",
                  }}
                >
                  <Button
                    onClick={handleCopyError}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      minWidth: "auto",
                      p: 0.5,
                    }}
                  >
                    <Copy size={16} />
                  </Button>
                  <Typography
                    component="pre"
                    sx={{
                      m: 0,
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                      color: "error.main",
                      pr: 4,
                    }}
                  >
                    {errorDetails}
                  </Typography>
                </Paper>
              </Collapse>
            </Box>
          )}

          {content.buttonText && (
            <Button
              onClick={onClose}
              variant="contained"
              sx={{
                mt: 2,
                borderRadius: "80px",
                border: "4px solid #FFAE5F",
                fontWeight: 400,
                color: "black",
                textTransform: "none",
                backgroundColor: "#FFC389",
                "&:hover": {
                  backgroundColor: "#FFB370",
                },
              }}
            >
              {content.buttonText}
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default TransactionModal;
