import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "./components/layout/nav";
import Home from "./components/layout/home";
import About from "./components/layout/about";
import HowToBuy from "./components/layout/howToBuy";
import Tokenomics from "./components/layout/tokenomics";
import Roadmap from "./components/layout/roadmap";
import FAQ from "./components/layout/Faq";
import Footer from "./components/layout/footer";

import "./App.css"; // Import custom styles

// Create a custom theme with background color and typography
const theme = createTheme({
  palette: {
    background: {
      default: "linear-gradient(180deg, #B2FFF9 0%, #FFFFFF 100%)",
    },
  },
  typography: {
    fontFamily: '"Comic Sans MS"',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "linear-gradient(180deg, #B2FFF9 90%, #FFFFFF 100%)",
          // backgroundAttachment: "fixed", // This prevents the gradient from scrolling
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <Home />
      <About />
      <HowToBuy />
      <Tokenomics />
      <Roadmap />
      <FAQ />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
