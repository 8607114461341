import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import tokenomicsData from "../../data/tokenomics.json"; // Import the JSON data

// Register the components
ChartJS.register(ArcElement, Tooltip, Legend);

const ds = () => {
  // Extracting category names and quantities from the JSON data
  const labels = tokenomicsData.table.map((item) => item.category);
  const quantities = tokenomicsData.table.map((item) => item.quantity);

  const data = {
    labels: labels,
    datasets: [
      {
        data: quantities,
        backgroundColor: [
          "rgba(123, 217, 179, 1)", // Light Orange
          "rgba(134, 190, 128, 1)",
          "rgba(143, 166, 77, 1)",
          "rgba(255, 140, 26, 1)",
          "rgba(204, 110, 20, 1)", // Darker Orange
          "rgba(153, 82, 15, 1)", // Darkest Orange
        ],
        hoverBackgroundColor: [
          "rgba(255, 217, 179, 0.8)",
          "rgba(255, 190, 128, 0.8)",
          "rgba(255, 166, 77, 0.8)",
          "rgba(123, 217, 179, 1)", // Light Orange
          "rgba(134, 190, 128, 1)",
          "rgba(143, 166, 77, 1)",
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          color: "black",
          font: {
            family: '"Comic Sans MS"',
            weight: "Bold",
          },
        },
      },
    },
    maintainAspectRatio: false, // Allows custom width and height
  };

  return (
    <div className="pie-chart-wrapper">
      <Doughnut data={data} options={options} width={400} height={400} />
    </div>
  );
};

export default ds;
