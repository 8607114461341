import React from "react";
import { Box, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";

const MarqueeSection = ({ marqueeContent }) => (
  <Marquee
    gradient={true}
    gradientColor={["#000000"]}
    gradientWidth={100}
    speed={50}
    pauseOnClick
    direction="left"
    loop={0}
    autoFill={true}
  >
    {marqueeContent.map((item, index) => (
      <Box
        key={index}
        sx={{
          display: "flex",
          alignItems: "center",
          mx: 2,
          mt: 4,
          gap: 2,
          color: "white",
        }}
      >
        <Box
          component="img"
          src={item.logo}
          alt={item.text}
          sx={{ width: 40 }}
        />
        <Typography>{item.text}</Typography>
      </Box>
    ))}
  </Marquee>
);

export default MarqueeSection;
